<template>
  <v-container
    fluid
    class="border-top pa-0 pt-5 margin-bottom-footer-tab-one"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <div>
          <div class="card-content">
            <div class="display-flex align-items-center border-bottom pa-3">
              <p class="text-header mon-medium">{{ texts.userprofile.tab1.table.textHeader1 }}</p>
              <v-spacer></v-spacer>
              <p
                v-if="arrUserProfile.sName"
                class="text-result mon-regular"
              >{{ arrUserProfile.sName }}</p>
              <empty-text-component v-else />
            </div>
            <div class="display-flex align-items-center border-bottom pa-3">
              <p class="text-header mon-medium">{{ texts.userprofile.tab1.table.textHeader2 }}</p>
              <v-spacer></v-spacer>
              <p
                v-if="arrUserProfile.sLastname"
                class="text-result mon-regular"
              >{{ arrUserProfile.sLastname }}</p>
              <empty-text-component v-else />
            </div>
            <div class="display-flex align-items-center border-bottom pa-3">
              <p class="text-header mon-medium">{{ texts.userprofile.tab1.table.textHeader3 }}</p>
              <v-spacer></v-spacer>
              <p
                v-if="arrUserProfile.sEmail"
                class="text-result mon-regular"
              >{{ arrUserProfile.sEmail }}</p>
              <empty-text-component v-else />
            </div>
            <div class="display-flex align-items-center border-bottom pa-3">
              <p class="text-header mon-medium">{{ texts.userprofile.tab1.table.textHeader4 }}</p>
              <v-spacer></v-spacer>
              <p
                v-if="arrUserProfile.sPositionName"
                class="text-result mon-regular"
              >{{ arrUserProfile.sPositionName }}</p>
              <empty-text-component v-else />
            </div>
            <div class="display-flex align-items-center border-bottom pa-3">
              <p class="text-header mon-medium">{{ texts.userprofile.tab1.table.textHeader5 }}</p>
              <v-spacer></v-spacer>
              <p
                v-if="arrUserProfile.sPhoneNumber"
                class="text-result mon-regular"
              >{{ arrUserProfile.sPhoneNumber }}</p>
              <empty-text-component v-else />
            </div>
            <div class="display-flex align-items-center pa-3">
              <p class="text-header mon-medium">{{ texts.userprofile.textHeaderVerify }}</p>
              <v-spacer></v-spacer>
              <p
                v-if="arrUserProfile.bVerifiedEnterprise"
                class="text-result mon-regular"
              >
                {{ texts.userprofile.textYesVerified }}
              </p>
              <p
                v-else
                class="text-result mon-regular"
              >
                {{ texts.userprofile.textNoVerified }}
              </p>
            </div>
          </div>
          <div class="border-bottom mt-5" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="ajust-button display-flex align-items-center">
          <v-btn
            @click="$store.commit('setProfileDialogChangePassword',true)"
            class="button-secondary mon-regular"
            elevation="0"
          >
            {{ texts.userprofile.tab1.textChangePassword }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="dialogEdit"
            class="button-primary mon-bold"
            elevation="0"
          >
            {{ texts.userprofile.tab1.textButtonModify }}
            <v-icon
              class="ml-1"
              color="#283c4d"
              size="16px"
            >
              mdi-pencil
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UserProfileContentTabOne",
  props: {
    texts: {
      type: Object,
    },
    tab: {
      type: Number,
    },
  },
  data() {
    return {
      //VARIABLES
      arrUserProfile: [],
    };
  },
  beforeMount() {
    this.getUserData();
  },
  methods: {
    dialogEdit: function () {
      this.$store.commit("setProfileDialogEdit", {
        active: true,
        arr: this.arrUserProfile,
      });
    },
    getUserData: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/employees/${this.$store.state.sUserId}`,
        config
      )
        .then((response) => {
          this.arrUserProfile = response.data.results;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getUserData();
      }
    },
    refresh: function () {
      this.getUserData();
    },
    tab: function () {
      if (this.tab == 0) {
        this.getUserData();
      }
    },
  },
};
</script>

<style scoped>
.text-header {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.margin-bottom-footer-tab-one {
  margin-bottom: 0px !important;
}

.text-result {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.border-top {
  border-top: 1px solid #e0e0e0;
}

@media (max-width: 500px) {
  .ajust-button {
    display: block;
  }
  .ajust-button button {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>